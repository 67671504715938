<template>
	<div v-loading="showLoading" class="company-list">
		<div class="opt">
			<el-button type="primary" @click="add">增加单位</el-button>
		</div>
		<el-table :data="data" stripe>
			<el-table-column
				v-for="item in columns"
				:key="item.prop"
				:prop="item.prop"
				:label="item.label"
			>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button
						size="small"
						@click="modify(scope.row)"
					>修改信息</el-button>
					<el-button
						size="small"
						type="danger"
						@click="deleteCompany(scope.row.id)"
					>删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog
			v-loading="addDialogLoading"
			:visible.sync="showAddDialog"
			:title="operationType === 'add' ? '增加单位' : '修改单位'"
			@close="$refs.companyForm.clearValidate()"
		>
			<el-form ref="companyForm" :model="formData" :rules="rules">
				<el-form-item label="名称" prop="name">
					<el-input v-model.trim="formData.name"></el-input>
				</el-form-item>
				<el-form-item label="层级" prop="role">
					<el-checkbox-group v-model="formData.role">
						<el-checkbox
							v-for="item in roleList"
							:key="item.value"
							:label="item.value"
						>{{item.name}}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="单位简介" prop="description">
					<el-input v-model.trim="formData.description"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="showAddDialog = false">取 消</el-button>
				<el-button type="primary" @click="confirm">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
    import {getCompanyList, addCompany, editCompany, deleteCompany} from '../service/main';
    import {ROLE_MAP} from '../utils/commonUtils';

    export default {
        name: 'CompanyList',
        data() {
            return {
                showLoading: false,
                columns: [
                    {
                        prop: 'name',
                        label: '公司名称'
                    },
                    {
                        prop: 'description',
                        label: '描述'
                    },
                    {
                        prop: 'roles',
                        label: '拥有层级'
                    },
                ],
                data: [],
	            showAddDialog: false,
	            formData: {
                    role: []
	            },
	            rules: {
                    name: [
                        {required: true, message: '请输入单位名称', trigger: 'blur'},
                    ],
		            role: [
                        { type: 'array', required: true, message: '请至少选择一个层级', trigger: 'change' }
		            ]
	            },
                addDialogLoading: false,
	            roleList: this.initRoleList(),
	            operationType: 'add'
            }
        },
        mounted() {
            this.query()
        },
        methods: {
            initRoleList() {
                const result = []
                Object.keys(ROLE_MAP).forEach(key => {
                    result.push({value: +key, name: ROLE_MAP[key]})
                })
	            return result
            },
	        add() {
		        this.formData = {
		            role: []
		        }
		        this.operationType = 'add'
                this.showAddDialog = true
	        },
            modify(row = {}) {
                this.formData = Object.assign({}, row)
                const targetRole = row.role.split(',').filter(v => Object.keys(ROLE_MAP).includes(v)).map(item => +item)
                this.formData.role = targetRole
                this.operationType = 'edit'
                this.showAddDialog = true
            },
            transformRole(role) {
                const tempArr = role.split(',')
                let result = ''
                let prefix = ''
                tempArr.forEach(item => {
                    if (!ROLE_MAP[+item]) {
                        return
                    }
                    result += prefix + ROLE_MAP[+item]
	                prefix = ','
                })
                return result
            },
            query() {
                this.showLoading = true
                getCompanyList({all: 1}).then(res => {
                    this.data = res.data.list.map(item => {
                        const temp = {...item}
                        temp.roles = this.transformRole(temp.role)
                        return temp
                    });
                    this.showLoading = false;
                }).catch(err => {
                    this.showLoading = false;
                    this.handleError(err)
                })
            },
            deleteCompany(id) {
                this.$confirm('是否删除该单位?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.showLoading = true
                    deleteCompany({id}).then(res => {
                        const {code, msg} = res
                        if (+code === 0) {
                            this.$message.success('删除成功')
                            this.query()
                        } else {
                            this.$message.error(msg)
                        }
                    }).catch(err => {
                        this.showLoading = false
                        this.handleError(err)
                    });
                }).catch(() => {
                    console.log('cancel delete user')
                });
            },
            confirm() {
                this.$refs.companyForm.validate(valid => {
                    if (valid) {
                        this.addDialogLoading = true
                        const params = Object.assign({}, this.formData)
                        const isAddType = this.operationType === 'add'
	                    const api = isAddType ? addCompany : editCompany
                        api(params).then(res => {
	                        this.addDialogLoading = false
                            const {code, msg} = res
                            if (+code === 0) {
                                this.$message.success(isAddType ? '添加成功' : '修改成功')
                                this.showAddDialog = false
                                this.query();
                            } else {
                                this.$message.error(msg);
                            }
                        }).catch(err => {
                            this.addDialogLoading = false;
                            this.showLoading = false;
                            this.handleError(err)
                        });
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
	.company-list {
		.opt {
			margin-bottom: 16px;
			text-align: left;
		}
	}
</style>
